const fontOptions = [
  'Lato',
  'Montserrat',
  'Nunito',
  'Open Sans',
  'Raleway',
  'Rubik',
  'Source Sans Pro',
  'Titillium Web',
];

export default fontOptions;
